import { invokeApi } from "../invokeApi";

export const _get_list_notification = async (limit) => {
  const requestObj = {
    path: `api/notification/list_notification?limit=${limit}&page=0`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_mark_as_seen_notification = async (notification_id) => {
  const requestObj = {
    path: `api/notification/mark_as_seen_notification/${notification_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_mark_as_seen_all_notification = async () => {
  const requestObj = {
    path: `api/notification/mark_as_seen_all_notification`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// export const _get_list_message_load_more_url = async (load_more_url) => {
//   const requestObj = {
//     path: `${load_more_url}`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };
