import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardActions,
  IconButton,
  TextareaAutosize,
  Box,
  CircularProgress,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import SendIcon from "@mui/icons-material/Send";
import Iconify from "../../../components/Iconify";
import CustomAvatar from "./CustomAvatar";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import MenuPopover from "../../../components/menuOption/MenuPopover";
import { s3BaseUrl } from "../../../config/config";
import { UserIcon } from "../../../assets";
import { styled } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import MicIcon from "@mui/icons-material/Mic";
import CustomPopoverSection from "../../../components/menuOption/CustomPopoverSection";
import { useSnackbar } from "notistack";
import {
  _get_list_message,
  _get_list_message_load_more_url,
  _get_mark_as_read_message,
} from "../../../DAL/Chat/Chat";
import { useAdminContext } from "../../../Hooks/AdminContext";
import moment from "moment";
import CustomCircularProgress from "../../../components/CustomCircularProgress";
import VoiceRecorder from "./VoiceRecorder";
import CustomTextareaAutosize from "./CustomTextareaAutosize";
import ReactMarkdown from "react-markdown";
import AudioPlayer from "./AudioPlayer";

const Input = styled("input")({
  display: "none",
});

const ChatBox = ({
  handleSubmit,
  inputs,
  setInputs,
  previews,
  setPreviews,
  MENU_OPTIONS,
  users,
  user,
  activeRow,
  messages,
  setMessages,
  editRow,
  handleClickUpdateCancel,
  setFileImage,
  userInformation,
  setUserInformation,
  isloadmessage,
  setIsloadmessage,
  socket,
  audioFile,
  setAudioFile,
  submitButtonLoading,
  setSubmitButtonLoading,
  setSecondsElapsed,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useAdminContext();
  const [isRecording, setIsRecording] = useState(false);
  const voiceRecorderRef = useRef(null);
  const messagesEndRef = useRef(null);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isStartRecording, setIsStartRecording] = useState(false);
  const [isStopRecording, setIsStopRecording] = useState(false);
  const [loadMoreURL, setLoadMoreURL] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmojiSelect = (emoji) => {
    setInputs((values) => ({
      ...values,
      message: (values?.message || "") + emoji.native,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleUpload = (event) => {
    setFileImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleRemoveImage = () => {
    setFileImage(null);
    setInputs((prev) => ({
      ...prev,
      profile_image: null,
      oldimage: null,
    }));
    setPreviews("");
  };

  const handleStartRecording = () => {
    setIsStartRecording(true);
    setIsRecording(true);
  };

  const loadOlderMessages = async (_loadMoreURL) => {
    const previousScrollHeight = messagesEndRef?.current?.scrollHeight;
    const result = await _get_list_message_load_more_url(_loadMoreURL); // Replace with your actual API call
    if (result.code === 200) {
      setLoadMoreURL(result?.load_more_url);
      setLoadMoreURL(result?.load_more_url);
      setMessages((prevMessages) => [...result?.message_list, ...prevMessages]);
      setTimeout(() => {
        const newScrollHeight = messagesEndRef.current.scrollHeight;
        messagesEndRef.current.scrollTop =
          newScrollHeight - previousScrollHeight;
      }, 0);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_list_message = async (_activeRow) => {
    setIsLoading(true);
    const result = await _get_list_message(_activeRow?._id);
    if (result.code === 200) {
      setLoadMoreURL(result?.load_more_url);
      let processedMessages = result?.message_list;
      let otherProcessedMessages = [];
      const userId = userInfo?.user_id?._id;
      new Promise((resolve) => {
        processedMessages = result?.message_list.map((item) => {
          return {
            ...item,
          };
        });

        otherProcessedMessages = result?.message_list.filter(
          (item) => item?.sender?.user_id !== userId
        );

        const lastIndex =
          otherProcessedMessages.length > 0
            ? otherProcessedMessages.length - 1
            : 0;
        const lastMessage = otherProcessedMessages[lastIndex];
        const lastMessageID = lastMessage?._id;
        const lastChatID = lastMessage?.chat_id;
        const senderUserID = lastMessage?.sender?.user_id;

        resolve({ lastMessageID, lastChatID, senderUserID });
      }).then(({ lastMessageID, lastChatID, senderUserID }) => {
        if (lastMessageID && lastChatID && senderUserID) {
          let postMessage = {
            user_id: senderUserID,
            chat_id: lastChatID,
            message_id: lastMessageID,
          };

          if (socket && postMessage) {
            socket.emit("mark_as_read_message", postMessage);
          }
        }
        setMessages(processedMessages);
        setIsLoading(false);
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      if (isloadmessage == false) {
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
      }
    }
  }, [messages, previews, isloadmessage]);

  useEffect(() => {
    if (user?.participant?.length > 0) {
      const newUser = user.participant.find(
        (participant) =>
          participant.action_user === 1 || participant.action_user === 2
      );

      setUserInformation(newUser);
    }

    if (activeRow?._id) {
      get_list_message(activeRow);
    } else {
      setMessages([]);
    }
  }, [activeRow]);

  useEffect(() => {
    const newUserFromUsers = users.find((item) => item?._id === activeRow?._id);

    if (newUserFromUsers) {
      if (newUserFromUsers?.participant?.length > 0) {
        const newUser = newUserFromUsers.participant.find(
          (participant) =>
            participant.action_user === 1 || participant.action_user === 2
        );

        setUserInformation(newUser);
      }
    }
  }, [users]);

  const handleScroll = useCallback(() => {
    if (messagesEndRef.current.scrollTop === 0 && !isLoading) {
      setIsloadmessage(true);
      loadOlderMessages(loadMoreURL);
    }
  }, [isLoading, loadMoreURL]);

  return (
    <>
      <section>
        <div className="px-0">
          <div className="row d-flex justify-content-end">
            <Card id="chat2" sx={{ borderRadius: "0px", boxShadow: "none" }}>
              <div
                className=" border-bottom "
                style={{ marginLeft: "67px", marginRight: "69px" }}
              >
                <div className="d-flex justify-content-between align-items-center pt-2 pb-2">
                  <div className="d-flex flex-row">
                    <div>
                      <CustomAvatar
                        src={
                          userInformation?.profile_image
                            ? s3BaseUrl + userInformation?.profile_image
                            : ""
                        }
                        alt={userInformation?.first_name}
                        sx={{ width: 50, height: 50 }}
                        status={userInformation?.is_online}
                      />
                    </div>
                    <div className="pt-3">
                      <p className="fw-bold mb-1">
                        {" "}
                        {userInformation?.first_name}{" "}
                        {userInformation?.last_name}
                      </p>
                      {/* <p className="small message-time ">{user.activeTime}</p> */}
                    </div>
                  </div>
                </div>
              </div>

              {isLoading ? (
                <CustomCircularProgress minHeight="64vh" />
              ) : (
                <CardContent
                  ref={messagesEndRef}
                  onScroll={handleScroll}
                  style={{
                    position: "relative",
                    height: "64vh",
                    overflowY: "auto",
                  }}
                >
                  {messages.map((msg) => {
                    let position = "left";

                    if (userInfo?.user_id?._id == msg?.sender?.user_id) {
                      position = "right";
                    }

                    let filteredMenuOptions =
                      msg?.message_content_type === "audio"
                        ? MENU_OPTIONS.filter(
                            (option) => option.label !== "Edit"
                          )
                        : MENU_OPTIONS;

                    return (
                      <div
                        key={msg._id}
                        className={`d-flex flex-row message-row ${
                          position === "left"
                            ? "justify-content-start"
                            : "justify-content-end"
                        }`}
                      >
                        {position === "right" && (
                          <>
                            <div className="chat-menu mt-2">
                              {filteredMenuOptions?.length > 0 && (
                                <CustomPopoverSection
                                  menu={filteredMenuOptions}
                                  data={msg}
                                />
                              )}
                            </div>

                            <div className="chat-menu-hide mt-2">
                              <div
                                style={{
                                  width: "25px",
                                  height: "25px",
                                }}
                              ></div>
                            </div>
                          </>
                        )}

                        {position === "left" && (
                          <Avatar
                            src={
                              msg?.sender?.profile_image
                                ? s3BaseUrl + msg?.sender?.profile_image
                                : ""
                            }
                            alt={msg?.sender?.first_name}
                            sx={{ width: 35, height: 35 }}
                            className="mt-3"
                          />
                        )}
                        <div
                          className={` width-80 ${
                            position === "left" ? "ms-3" : "me-3"
                          } mb-3 p-2 rounded-3`}
                        >
                          <div
                            className={`message-text small mb-1 ${
                              position === "left" ? "text-start" : "text-end"
                            }`}
                          >
                            {msg?.message_content_type == "image" &&
                              msg?.image && (
                                <img
                                  src={
                                    msg?.image
                                      ? s3BaseUrl + msg?.image
                                      : UserIcon
                                  }
                                  alt={
                                    msg?.message_content
                                      ? msg?.message_content
                                      : "i"
                                  }
                                  style={{
                                    maxWidth: "280px",
                                    maxHeight: "280px",
                                    height: "auto",
                                  }}
                                  className="rounded-3"
                                />
                              )}

                            {msg?.message_content_type == "audio" &&
                              msg?.audio_url && (
                                <Box sx={{ marginLeft: 0 }}>
                                  <AudioPlayer
                                    audioUrl={
                                      msg?.audio_url
                                        ? s3BaseUrl + msg?.audio_url
                                        : ""
                                    }
                                    audioDuration={msg?.audio_duration}
                                  />
                                  {/* <audio
                                    src={
                                      msg?.audio_url
                                        ? s3BaseUrl + msg?.audio_url
                                        : ""
                                    }
                                    controls
                                  /> */}
                                </Box>
                              )}
                          </div>

                          {msg?.message_content && (
                            <div
                              className={`p-2 rounded-3 ${
                                position === "left" ? "left-side" : "right-side"
                              }`}
                            >
                              <p className="text-start mb-0">
                                {msg?.message_content && (
                                  <ReactMarkdown>
                                    {msg?.message_content}
                                  </ReactMarkdown>
                                )}
                              </p>
                            </div>
                          )}
                          <p className="small mb-0 text-end">
                            <span className="ms-0">
                              <Iconify
                                icon={
                                  msg?.status == "sent"
                                    ? "ph:check"
                                    : msg?.status == "delivered"
                                    ? "line-md:check-all"
                                    : msg?.status == "read"
                                    ? "line-md:check-all"
                                    : "line-md:check-all"
                                }
                                width={14}
                                height={14}
                                className={
                                  msg?.status == "read" ? "icon-check" : ""
                                }
                              />
                            </span>
                            <span className="ms-0 message-time">
                              {moment(msg?.message_date_time).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </span>
                          </p>
                        </div>
                        {position === "right" && (
                          <Avatar
                            src={
                              msg?.sender?.profile_image
                                ? s3BaseUrl + msg?.sender?.profile_image
                                : ""
                            }
                            alt={msg?.sender?.first_name}
                            sx={{ width: 35, height: 35 }}
                            className="mt-3"
                          />
                        )}
                      </div>
                    );
                  })}
                </CardContent>
              )}

              <form
                className="row"
                onSubmit={handleSubmit}
                style={{
                  position: "relative",
                }}
              >
                {/* {previews && (
                  <div
                    className="d-flex flex-row justify-content-start"
                    style={{
                      position: "absolute",
                      top: -79,
                      right: -16,
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <Avatar
                        src={
                          previews
                            ? previews
                            : inputs.oldimage
                            ? s3BaseUrl + inputs.oldimage
                            : UserIcon
                        }
                        alt={"Image"}
                        sx={{ width: 100, height: 80 }}
                        className="before-send-image"
                      />
                      {previews || inputs.oldimage ? (
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: -27,
                            right: -25,
                          }}
                          onClick={handleRemoveImage}
                          aria-label="remove picture"
                          color="error"
                        >
                          <CancelIcon className="chat-image-remove-cross-button" />
                        </IconButton>
                      ) : null}
                    </div>
                  </div>
                )} */}

                <CardActions className="text-muted d-flex justify-content-start align-items-center p-2">
                  {!isRecording ? (
                    <>
                      {!previews && (
                        <label
                          htmlFor="icon-button-file"
                          style={{ alignSelf: "flex-end" }}
                        >
                          <Input
                            accept="image/*"
                            id="icon-button-file"
                            type="file"
                            name="profile_image"
                            onChange={(e) => handleUpload(e)}
                          />

                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <AddPhotoAlternateIcon />
                          </IconButton>
                        </label>
                      )}

                      <IconButton
                        ref={anchorRef}
                        color="primary"
                        onClick={handleOpen}
                        sx={{ alignSelf: "flex-end" }}
                      >
                        <EmojiEmotionsIcon />
                      </IconButton>

                      {/* <TextareaAutosize
                        minRows={1}
                        maxRows={6}
                        placeholder="Type a message"
                        className="textarea-custom mx-2"
                        id="outlined-basic"
                        name="message"
                        value={inputs?.message}
                        onChange={handleChange}
                        style={{ flex: 1 }}
                      /> */}
                      <CustomTextareaAutosize
                        inputs={inputs}
                        setInputs={setInputs}
                        handleChange={handleChange}
                        placeholder="Type a message"
                        className="textarea-custom mx-2"
                        name="message"
                        previews={previews}
                        handleRemoveImage={handleRemoveImage}
                      />

                      {submitButtonLoading ? (
                        <IconButton
                          color="primary"
                          sx={{ alignSelf: "flex-end" }}
                        >
                          <CircularProgress size={24} color="inherit" />
                        </IconButton>
                      ) : inputs?.message === "" &&
                        !previews &&
                        !inputs.oldimage &&
                        !editRow ? (
                        <IconButton
                          color="primary"
                          onClick={handleStartRecording}
                          sx={{ alignSelf: "flex-end" }}
                        >
                          <MicIcon className="chat-image-remove-cross-button" />
                        </IconButton>
                      ) : (
                        <IconButton
                          type="submit"
                          color="primary"
                          sx={{ alignSelf: "flex-end" }}
                        >
                          <SendIcon />
                        </IconButton>
                      )}

                      {editRow && (
                        <IconButton
                          color="primary"
                          onClick={handleClickUpdateCancel}
                          sx={{ alignSelf: "flex-end" }}
                        >
                          <CancelIcon className="chat-image-remove-cross-button" />
                        </IconButton>
                      )}
                    </>
                  ) : (
                    <VoiceRecorder
                      handleSubmit={handleSubmit}
                      audioFile={audioFile}
                      setAudioFile={setAudioFile}
                      ref={voiceRecorderRef}
                      isRecording={isRecording}
                      setIsRecording={setIsRecording}
                      isStartRecording={isStartRecording}
                      setIsStartRecording={setIsStartRecording}
                      isStopRecording={isStopRecording}
                      setIsStopRecording={setIsStopRecording}
                      setInputs={setInputs}
                      setSubmitButtonLoading={setSubmitButtonLoading}
                      setSecondsElapsed={setSecondsElapsed}
                    />
                  )}
                </CardActions>
              </form>

              <MenuPopover
                className="custom-popover"
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  marginLeft: 0,
                  maxHeight: 400,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <Picker
                    data={data}
                    onEmojiSelect={handleEmojiSelect}
                    style={{ width: "100%" }}
                  />
                </div>
              </MenuPopover>
            </Card>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChatBox;
