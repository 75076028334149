import React, { useState } from "react";
import CustomAvatar from "./CustomAvatar";
import { s3BaseUrl } from "../../../config/config";

const OnlineOfflineUsercomponent = ({
  users,
  is_online = true,
  addUserIfNotExists,
}) => {
  const [filteredUsers, setFilteredUsers] = useState(users);

  // useEffect(() => {
  //   let newUsers = users;
  //   const existingUser = users.find((user) => user._id === usersOffline._id);

  //   if (!existingUser) {
  //     newUsers = [...users, usersOffline];
  //   }

  //   setFilteredUsers(
  //     newUsers.filter((user) =>
  //       user?.first_name.toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  //   );
  //   setFilteredUsers(newUsers);
  // }, [searchTerm, users]);

  return (
    <div>
      <ul className="list-unstyled mb-0">
        {filteredUsers
          .filter((user) => user?.is_online === is_online)
          .map((user, index) => (
            <li
              onClick={() => addUserIfNotExists(user)}
              key={index}
              className="p-2 border-bottom pointer chat-profile"
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-row">
                  <div>
                    <CustomAvatar
                      src={user?.image ? s3BaseUrl + user?.image : ""}
                      alt={user?.first_name}
                      sx={{ width: 40, height: 40 }}
                      status={user?.is_online}
                    />
                  </div>
                  <div className="pt-2">
                    <p className="fw-bold mb-0">
                      {user?.first_name} {user?.last_name}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default OnlineOfflineUsercomponent;
