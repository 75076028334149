import React, { useState, useRef, useEffect } from "react";
import { Box, IconButton, Typography, LinearProgress } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

const AudioPlayer = ({ audioUrl, audioDuration }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(audioDuration ? audioDuration : 0);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;

    const updateCurrentTime = () => setCurrentTime(audio.currentTime);

    // const updateDuration = () => {
    //   // Ensure metadata is fully loaded before setting the duration
    //   if (audio.duration && !isNaN(audio.duration)) {
    //     setDuration(audio.duration);
    //   }
    // };

    const handleAudioEnd = () => {
      setIsPlaying(false);
      setCurrentTime(0); // Reset to the start
      audio.currentTime = 0; // Reset audio playback position
    };

    if (audio) {
      audio.addEventListener("timeupdate", updateCurrentTime);
      //   audio.addEventListener("loadedmetadata", updateDuration);
      audio.addEventListener("ended", handleAudioEnd);
    }

    return () => {
      if (audio) {
        audio.removeEventListener("timeupdate", updateCurrentTime);
        // audio.removeEventListener("loadedmetadata", updateDuration);
        audio.removeEventListener("ended", handleAudioEnd);
      }
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (!audio) return;

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatTime = (time) => {
    if (isNaN(time)) return "00:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "8px 12px",
        backgroundColor: "#f0f0f0",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "300px",
      }}
    >
      <IconButton
        onClick={togglePlayPause}
        size="small"
        sx={{
          color: isPlaying ? "#007bff" : "#555",
        }}
      >
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
      <Box sx={{ flex: 1, mx: 1 }}>
        <LinearProgress
          variant="determinate"
          value={(currentTime / duration) * 100 || 0}
          sx={{
            height: 4,
            borderRadius: 2,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#007bff",
            },
          }}
        />
      </Box>
      <Typography variant="caption" sx={{ color: "#555", marginLeft: 1 }}>
        {formatTime(currentTime)} / {formatTime(duration)}
      </Typography>
      <audio ref={audioRef} src={audioUrl || ""} />
    </Box>
  );
};

export default AudioPlayer;
