import React, { useEffect, useState } from "react";
import CustomTabs from "../../../components/CustomTabs";
import {
  _get_exist_chat,
  _get_online_offline_list_business_for_chat,
} from "../../../DAL/Chat/Chat";
import CustomCircularProgress from "../../../components/CustomCircularProgress";
import OnlineOfflineUsercomponent from "./OnlineOfflineUsercomponent";
import { useSnackbar } from "notistack";

const UserOnlineOfflineList = ({
  setActiveRow,
  setIsOpenDrawer,
  setIsChatExist,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState(0);
  const [isTabLoading, setIsTabLoading] = useState(false);
  const [firstTimePageLoad, setFirstTimePageLoad] = useState(true);
  const [onlineBusinessList, setOnlineBusinessList] = useState([]);
  const [offlineBusinessList, setOfflineBusinessList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const get_exist_chat = async (newUser) => {
    const result = await _get_exist_chat(newUser?.user_id);

    if (result.code === 200) {
      setIsChatExist(result?.is_chat_exist);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const addUserIfNotExists = (newUser) => {
    let newActiveRow = {
      participant: [
        {
          ...newUser,
          unread_message_count: 0,
          is_chat_initiated: false,
          profile_image: newUser?.image,
        },
      ],
      last_message: {},
    };
    setActiveRow(newActiveRow);
    get_exist_chat(newUser);
    setIsOpenDrawer(false);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS_OPTIONS = [
    {
      title: "Online",
      component: isTabLoading ? (
        <CustomCircularProgress minHeight="300px" />
      ) : (
        <>
          <OnlineOfflineUsercomponent
            users={onlineBusinessList}
            addUserIfNotExists={addUserIfNotExists}
            is_online={true}
          />
        </>
      ),
    },

    {
      title: "Offline",
      component: isTabLoading ? (
        <CustomCircularProgress minHeight="300px" />
      ) : (
        <>
          <OnlineOfflineUsercomponent
            users={offlineBusinessList}
            addUserIfNotExists={addUserIfNotExists}
            is_online={false}
          />
        </>
      ),
    },
  ];

  const get_online_offline_list_business_for_chat = async (_tabValue) => {
    let selectedTabValue = _tabValue ? _tabValue : tabValue;
    let type = "online";
    if (selectedTabValue != 0) {
      type = "offline";
    }

    setIsTabLoading(true);
    const result = await _get_online_offline_list_business_for_chat(
      type,
      searchTerm
    );
    if (result.code === 200) {
      setOnlineBusinessList(result?.online_business_list);
      setOfflineBusinessList(result?.offline_business_list);

      setFirstTimePageLoad(false);
      setIsTabLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsTabLoading(false);
    }
  };

  useEffect(() => {
    get_online_offline_list_business_for_chat(tabValue);
  }, [tabValue]);

  useEffect(() => {
    if (!firstTimePageLoad) {
      const timeoutId = setTimeout(() => {
        get_online_offline_list_business_for_chat(tabValue);
      }, 700);

      return () => clearTimeout(timeoutId);
    }
  }, [searchTerm]);

  return (
    <div className="mt-2">
      <div className="input-group rounded p-2 pb-0">
        <input
          type="search"
          className="form-control rounded textarea-custom"
          placeholder="Search"
          aria-label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <CustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
      />
    </div>
  );
};

export default UserOnlineOfflineList;
