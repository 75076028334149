import React, { useState } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import IconButton from "@mui/material/IconButton";
import Iconify from "../../../components/Iconify";
import { useTheme } from "@mui/material/styles";
import InsertLinkModal from "./InsertLinkModal";
import { useSnackbar } from "notistack";
import { Avatar } from "@mui/material";
import { s3BaseUrl } from "../../../config/config";
import { UserIcon } from "../../../assets";
import CancelIcon from "@mui/icons-material/Cancel";

const CustomTextareaAutosize = ({
  inputs,
  setInputs,
  handleChange,
  className = "",
  minRows = 1,
  maxRows = 6,
  placeholder = "",
  name = "message",
  previews,
  handleRemoveImage,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isFocused, setIsFocused] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [linkData, setLinkData] = useState({
    display_text: "",
    link_address: "",
  });

  let iconsWidth = 15;
  let iconsHeight = 15;

  const handleGetSelectedText = () => {
    const textarea = document.getElementById("outlined-basic");
    return textarea.value.substring(
      textarea.selectionStart,
      textarea.selectionEnd
    );
  };

  const handleOnBold = () => {
    const textarea = document.getElementById("outlined-basic");
    const selectedText = handleGetSelectedText();
    if (selectedText) {
      const newText = textarea.value.replace(
        selectedText,
        `**${selectedText}**`
      );
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: newText,
      }));
    }
  };

  const handleOnItalic = () => {
    const textarea = document.getElementById("outlined-basic");
    const selectedText = handleGetSelectedText();
    if (selectedText) {
      const newText = textarea.value.replace(selectedText, `*${selectedText}*`);
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: newText,
      }));
    }
  };

  const handleAddLink = () => {
    const { display_text, link_address } = linkData;
    const make_link = `[${display_text}](${link_address})`;
    const textarea = document.getElementById("outlined-basic");
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    if (!display_text) {
      enqueueSnackbar("Text to Display is required!", {
        variant: "error",
      });
      return;
    }
    if (!link_address) {
      enqueueSnackbar("Link Address is required!", {
        variant: "error",
      });
      return;
    }

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]:
        prevInputs[name].slice(0, start) +
        make_link +
        prevInputs[name].slice(end),
    }));

    textarea.setSelectionRange(
      start + make_link.length,
      start + make_link.length
    );
    setOpenLinkModal(false);
    setLinkData({ display_text: "", link_address: "" });
  };

  const handleIconClick = (iconName) => {
    if (iconName === "bold") {
      handleOnBold();
    } else if (iconName === "italic") {
      handleOnItalic();
    } else if (iconName === "link") {
      setOpenLinkModal(true);
    }
  };

  return (
    <>
      <InsertLinkModal
        openPasswordModal={openLinkModal}
        setopenPasswordModal={setOpenLinkModal}
        linkData={linkData}
        setLinkData={setLinkData}
        handleAddLink={handleAddLink}
      />

      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 0,
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: "10px 10px 0 0",
            width: "100%",
            background: "#f5f5f5",
            borderBottom: "none",
            boxShadow: `0 0 0 2px #438a7a0f`,
            transform: showIcons
              ? `translateY(${previews ? "-135px" : "-35px"})`
              : "translateY(0)",
            opacity: showIcons ? 1 : 0,
            pointerEvents: showIcons ? "auto" : "none",
            transition: "transform 0.2s ease, opacity 0.1s ease",
          }}
        >
          {/* Icon buttons */}
          <IconButton
            onClick={() => handleIconClick("bold")}
            style={{ padding: "8px" }}
          >
            <Iconify
              icon="fluent:text-bold-16-regular"
              width={iconsWidth}
              height={iconsHeight}
            />
          </IconButton>
          <IconButton
            onClick={() => handleIconClick("italic")}
            style={{ padding: "8px" }}
          >
            <Iconify
              icon="fluent:text-italic-16-regular"
              width={iconsWidth}
              height={iconsHeight}
            />
          </IconButton>
          <IconButton
            onClick={() => handleIconClick("link")}
            style={{ padding: "8px" }}
          >
            <Iconify
              icon="material-symbols:link"
              width={iconsWidth + 3}
              height={iconsHeight + 3}
            />
          </IconButton>
        </div>
        {previews && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 0,
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: showIcons ? "0 0 0 0" : "10px 10px 0 0",
              width: "100%",
              height: 100,
              background: "#fff",
              borderBottom: "none",
              boxShadow: `0 0 0 2px #438a7a0f`,
              transform: previews ? "translateY(-100px)" : "translateY(0)",
              opacity: previews ? 1 : 0,
              pointerEvents: previews ? "auto" : "none",
              transition: "transform 0.4s ease, opacity 0.2s ease",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <Avatar
                src={
                  previews
                    ? previews
                    : inputs.oldimage
                    ? s3BaseUrl + inputs.oldimage
                    : UserIcon
                }
                alt={"Image"}
                sx={{ width: 100, height: 80 }}
                className="before-send-image"
              />
              {previews || inputs.oldimage ? (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: -9,
                    right: -13,
                  }}
                  onClick={handleRemoveImage}
                  aria-label="remove picture"
                  color="error"
                >
                  <CancelIcon className="chat-image-remove-cross-button" />
                </IconButton>
              ) : null}
            </div>
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: `1px solid ${
              isFocused || showIcons || previews
                ? theme.palette.primary.main
                : "#ccc"
            }`,
            borderRadius: showIcons || previews ? "0 0 4px 4px" : "4px",
            boxShadow:
              isFocused || showIcons || previews
                ? `0 0 0 2px #438a7a0f`
                : `0 0 0 0px #438a7a0f`,
            flex: 1,
          }}
        >
          <TextareaAutosize
            minRows={minRows}
            maxRows={maxRows}
            placeholder={placeholder}
            className={`${className} textarea-no-border`}
            id="outlined-basic"
            name={name}
            value={inputs?.message}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            style={{
              flex: 1,
              border: "none",
              outline: "none",
              padding: "8px",
              margin: 0,
            }}
          />

          <IconButton
            style={{ padding: "8px" }}
            onClick={() => setShowIcons(!showIcons)}
            sx={{ alignSelf: "flex-end" }}
          >
            <Iconify
              icon={
                showIcons
                  ? "eva:arrow-ios-downward-fill"
                  : "fluent:text-edit-style-character-a-32-regular"
              }
              width={20}
              height={20}
            />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default CustomTextareaAutosize;
