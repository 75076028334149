import PropTypes from "prop-types";

import { useState, useRef, useEffect } from "react";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import { styled, lighten } from "@mui/material/styles";
// utils
// components
// import { Iconify, Scrollbar, MenuPopover } from "src/components/";
// import notification_chat from "src/assets/media/notification_chat.svg";
// import {
//   teamNotificationAllRead,
//   teamNotificationRead,
// } from "src/DAL/notifications";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
// import { leaveIcon, sportIcon, feedbackIcon } from "src/assets";

// import NotificationConfirmation from "src/components/NotificationConfirmation";
// import DetailsToShow from "src/components/DetailsToShow";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import Iconify from "../../components/Iconify";
import MenuPopover from "../../components/menuOption/MenuPopover";
import Scrollbar from "../../components/Scrollbar";
import { useSocket } from "../../Hooks/SocketProvider";
import { fToNow } from "../../utils/formatTime";
import {
  _get_mark_as_seen_all_notification,
  _get_mark_as_seen_notification,
} from "../../DAL/Notification/Notification";
// ----------------------------------------------------------------------

const IconButtonStyle = styled(IconButton)(({ theme, custom_prop }) => {
  return {
    width: 40,
    height: 40,
    // backgroundColor: lighten(theme.palette.primary.lighter, 0.3),
    // backgroundColor: lighten(theme.palette.primary, 0.3),
    "&:hover": {
      backgroundColor: "#bfbfbf",
      // backgroundColor: theme.palette.primary.lighter,
    },
  };
});

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  // const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const {
    notifications,
    fetchNotificationListing,
    unreadNotifications,
    loading,
    setUnreadNotifications,
    setNotifications,
  } = useSocket();
  const [sidebarPermissions, setSidebarPermissions] = useState();
  const [openApprove, setOpenApprove] = useState(false);
  const [title, setTitle] = useState("");
  const [notific, setNotific] = useState();
  const [detailsData, setDetailsData] = useState("");
  const [openDetails, setOpenDetails] = useState(false);
  const [open, setOpen] = useState(null);
  let [limit, setLimit] = useState(10);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleApprove = () => {
    setOpenApprove(false);
  };
  const handleMarkAllAsRead = async () => {
    const result = await _get_mark_as_seen_all_notification();
    if (result.code === 200) {
      setUnreadNotifications(0);
      setNotifications(
        notifications.map((notification) => ({
          ...notification,
          is_seen: true,
        }))
      );
      handleClose();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  async function getDetailsOfSurveyTemplate(id) {
    // try {
    //   const res = await getFeedbackDetail(id);
    //   if (res.code === 200) {
    //     if (res?.feedback?.submission_status == "submitted") {
    //       navigate(`/feedback/details-feedback/${id}`);
    //     } else if (res?.feedback?.submission_status == "pending") {
    //       navigate(`/feedback/form-feedback/${id}`);
    //     }
    //   } else {
    //     enqueueSnackbar(res.message, { variant: "error" });
    //   }
    // } catch (error) {
    //   console.log(error);
    // } finally {
    // }
  }
  const readNotificationInList = async (_id, notification) => {
    const result = await _get_mark_as_seen_notification(_id);
    if (result.code === 200) {
      fetchNotificationListing(limit);
      handleClose();
      if (notification?.notification_type == "chat") {
        // setDetailsData(notification);
        // setOpenDetails(true);

        navigate("/chat");
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // useEffect(() => {
  //   setSidebarPermissions(profile?.privileges);
  // }, [profile?.privileges]);

  useEffect(() => {
    fetchNotificationListing(limit);
  }, [limit]);
  return (
    <>
      <IconButtonStyle
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        custom_prop={"custom-value"}
        sx={{ width: 40, height: 40, backgroundColor: "#d9d9d9" }}
      >
        <Badge
          sx={{ padding: "4px" }}
          badgeContent={unreadNotifications}
          color="error"
        >
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonStyle>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {unreadNotifications} unread messages
            </Typography>
          </Box>
          {unreadNotifications > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ maxHeight: { xs: 340, sm: 340 }, overflowY: "auto" }}>
          {/* <Box sx={{ maxHeight: 340, overflowY: 'auto' }}> */}
          <List
            disablePadding
            // subheader={
            //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
            //     New
            //   </ListSubheader>
            // }
          >
            {notifications &&
              notifications.map((notification, i) => (
                <NotificationItem
                  index={i}
                  readNotificationInList={readNotificationInList}
                  key={notification?._id}
                  notification={notification}
                />
              ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        {notifications.length > 7 && (
          <Box sx={{ p: 1 }}>
            <LoadingButton
              loading={loading}
              fullWidth
              onClick={() => {
                // limit = limit + 10;
                setLimit(limit + 10);
                fetchNotificationListing(limit);
              }}
              variant="contained"
            >
              View More
            </LoadingButton>
            {/* <Button
            fullWidth
            onClick={() => {
              // limit = limit + 10;
              setLimit(limit + 10);
              console.log(limit, "k");
              // fetchNotificationListing(limit);
            }}
          >
            View More
          </Button> */}
          </Box>
        )}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
    readNotificationInList: PropTypes.func,
    index: PropTypes.any,
  }),
};

function NotificationItem({ notification, readNotificationInList, index }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      onClick={() => {
        readNotificationInList(notification._id, notification);
      }}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(!notification?.is_seen && {
          bgcolor: "action.selected",
        }),
      }}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar> */}
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(moment(notification?.createdAt).format("LLL"))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {/* {notification?.title} */}
      {notification?.message}
      {/* <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {notification?.message}
      </Typography> */}
    </Typography>
  );
  // return {
  //   avatar: (
  //     <img loading="lazy" src={notification_chat} alt="notification-logo" />
  //   ),
  //   title,
  // };
  //---------------

  if (notification.type === "leave_request") {
    return {
      avatar: <img loading="lazy" alt={notification.title} src={"leaveIcon"} />,
      title,
    };
  } else
    return {
      avatar: (
        <img loading="lazy" src={"notification_chat"} alt="notification-logo" />
      ),
      title,
    };
  // if (notification.type === "mail") {
  //   return {
  //     avatar: (
  //       <img loading="lazy"
  //         alt={notification.title}
  //         src="/static/icons/ic_notification_mail.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: <img loading="lazy" alt={notification.title} src='/static/icons/ic_notification_chat.svg' />,
  //     title,
  //   };
  // }
  // return {
  //   avatar: notification.avatar ? <img loading="lazy" alt={notification.title} src={notification.avatar} /> : null,
  //   title,
  // };
}
