import { Box, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

CustomCircularProgress.propTypes = {
  minHeight: PropTypes.string,
};

export default function CustomCircularProgress({ minHeight }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: minHeight ? minHeight : "auto",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
