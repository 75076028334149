import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { xs: "100%", sm: 560 },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  padding: "10px 20px 20px 20px",
};

export default function InsertLinkModal({
  openPasswordModal,
  setopenPasswordModal,
  linkData,
  setLinkData,
  handleAddLink,
}) {
  const handleClose = () => {
    setopenPasswordModal(false);
    setLinkData({
      display_text: "",
      link_address: "",
    });
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    handleAddLink();
  };

  return (
    <div>
      <Modal
        disableTypography="true"
        open={openPasswordModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handlePasswordSubmit}>
          <Box sx={style} className="modal-theme">
            <div
              clo-12
              className="text-end modalIcon pointer"
              onClick={() => {
                handleClose();
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
            <Typography
              className="text-center mb-4"
              id="spring-modal-title"
              variant="h6"
              component="h2"
            >
              Insert Link
            </Typography>
            <div className="col-12 mt-3">
              <TextField
                fullWidth
                label="Text to Display"
                value={linkData.display_text}
                onChange={(e) =>
                  setLinkData((prev) => ({
                    ...prev,
                    display_text: e.target.value,
                  }))
                }
                name="display_text"
                required
              />
            </div>
            <div className="col-12 mt-3">
              <TextField
                fullWidth
                label="Link Address"
                value={linkData.link_address}
                onChange={(e) =>
                  setLinkData((prev) => ({
                    ...prev,
                    link_address: e.target.value,
                  }))
                }
                name="link_address"
                required
              />
            </div>
            <div className="col-12 mt-3">
              <Button
                onClick={handleAddLink}
                variant="contained"
                className="w-100"
              >
                Insert
              </Button>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
