import React from "react";
import { Toolbar, CssBaseline, AppBar, IconButton, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { _get_user_in_localStorage } from "../../local_storage/local_storage";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";

const drawerWidth = 260;

function AppHeader({ handleDrawerToggle, setIsLoading }) {
  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "#fff",
        }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <div className="d-flex align-items-center w-100 ">
            {/* <div className="position-relative search_input w-100 ">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                id="search-options"
              />
              <i className="fa-solid fa-magnifying-glass search-widget-icon"></i>
            </div> */}
          </div>
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.0, sm: 0.0 }}
          >
            <NotificationsPopover />
            <AccountPopover setIsLoading={setIsLoading} />
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default AppHeader;
